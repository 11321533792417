<template>
  <v-container >
    <v-row class="pl-15">
      <v-col
          class="d-flex"
          cols="12"
          sm="5"
      >
        <v-select
            :label="$t('generic.lang_filterByGroup')"
            outlined
            :items="IngredientGroups"
            item-text="name"
            item-value="uuid"
            clearable
            v-model = "ingFilter"
            @input="filterIngredient"
        ></v-select>
      </v-col>
      <v-col
          class="d-flex"
          cols="12"
          sm="5"
      >
        <v-autocomplete
            :label="$t('erp.lang_nav_recipe')"
            :readonly="false"
            item-text="name"
            :items="ingredientsArray"
            return-object
            deletable-chips
            outlined v-model="form.ingredientGroup"
        ></v-autocomplete>
      </v-col>
      <v-col
          class="d-flex"
          cols="12"
          sm="2"
      >
        <v-btn
            class="mx-2"
            fab
            small
            dark
            color="indigo"
            @click="addIngredient"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-list
        class="mx-auto"
        max-width="90%"
    >
      <v-row justify="center">
        <v-expansion-panels focusable>
          <v-expansion-panel
              v-for="(item,i) in ingredients"
              :key="i"
          >
            <v-expansion-panel-header color="grey lighten-5"><H5> {{ $t('generic.lang_name') }} : {{item.name}}</H5>
              <v-col cols="12" sm="4"  align="right">
                <v-btn
                    small
                    icon
                    color="red"
                    @click="deleteType(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
              <template v-slot:actions>
                <v-icon color="primary">
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t('generic.lang_name') }}
                    </th>
                    <th class="text-left">
                      {{$t('erp.lang_receiptUsedAmount')}}
                    </th>
                    <th class="text-left">
                      {{$t('erp.lang_receiptWastedAmount')}}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(meal) in meals"
                      :key="meal.id"
                  >
                    <td>{{ meal.name }}</td>
                    <td><v-col cols="12" md="12" sm="12">
                      <v-text-field :label="$t('erp.lang_receiptUsed')"
                                    outlined
                                    dense
                                    :suffix="item.unit_sign"
                                    v-model="item.used[meal.id]"

                      />
                    </v-col>
                    </td>
                    <td><v-col cols="12" md="12" sm="12">
                      <v-text-field :label="$t('erp.lang_wastedReceipt')"
                                    outlined
                                    dense
                                    :suffix="item.unit_sign"
                                    v-model="item.wasted[meal.id]"

                      />
                    </v-col>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-list>

  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../../config";
import {Events} from "../../../../plugins/events";

export default {
  name: "EditItemIngredient",
  components: {},
  props:['ingredients'],

  data() {
    return {
      IngredientGroups: [],
      meals : [],
      ingFilter : null,
      ingredientsArray : [],
      form: {
        IngredientGroups: null,
      },
    }
  },
  methods: {
    filterIngredient() {
      this.axios.post('get/erp/ingredient/byGroup/', {
        ingredientGrp : this.ingFilter
      }).then((res) => {
        this.ingredientsArray = res.data.ingredient == null ? [] : res.data.ingredient
      })
    },
    getIngredientGroups() {
      this.axios.post(ENDPOINTS.ERP.INGRREDIENTGROUP.GETALL).then((res) => {
        if (res.data.STATUS === "SUCCESS") {
          this.IngredientGroups = res.data.groups;
          this.IngredientGroups.unshift({uuid: null, name: this.$t('generic.lang_all').toLocaleUpperCase()});
        }
      }).catch(err => {

      })
    },
    loadMeals() {
      this.axios.post('get/indexeddb/mealSizes/', {
      }).then((res) => {
        this.meals = res.data
        this.meals.unshift({id: 0, name: "Standard Size", sorting: 0});

      })
    },
    deleteType(item) {
      this.$swal({
        title: this.$t('erp.lang_deleteIngredient'),
        text: this.$t('erp.lang_deleteIngredientBody'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.ingredients.splice(this.ingredients.indexOf(item), 1);

        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    addIngredient() {
      if(this.form.ingredientGroup.name!=""){
        this.ingredients.push({id : this.form.ingredientGroup.id,
          sellUnit :this.form.ingredientGroup.selling_unit ,unit_name :this.form.ingredientGroup.selling_unit_name ,unit_sign :this.form.ingredientGroup.selling_unit_sign ,
          name:this.form.ingredientGroup.name,wasted : {},used:{}});
        this.form.ingredientGroup = "";
      }

    },

  },
  mounted() {
    this.getIngredientGroups();
    this.loadMeals();
    this.filterIngredient();

  }
}
</script>